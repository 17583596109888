import * as Sentry from '@sentry/sveltekit';

import posthog from 'posthog-js';

posthog.init('phc_jMeQuUrDjZWbnYHXWnQF5M60NtmfDC69G2pP2eeymvM', {
	api_host: 'https://eu.i.posthog.com',
	person_profiles: 'always' // or 'always' to create profiles for anonymous users as well
});

// If you don't want to use Session Replay, remove the `Replay` integration,
// `replaysSessionSampleRate` and `replaysOnErrorSampleRate` options.
Sentry.init({
	dsn: 'https://408720f99b637ea3ed79122bdfb47839@o4506241271595008.ingest.sentry.io/4506398201282560',
	environment: import.meta.env.MODE,
	tracesSampleRate: 1,
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1,
	integrations: [new Sentry.Replay()]
});

export const handleError = Sentry.handleErrorWithSentry();
